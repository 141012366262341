import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IndexComponent } from "./index/index.component";
import { AcknowledgementComponent } from "./acknowledgement/acknowledgement.component";
import { PitchsComponent } from "./pitchs/pitchs.component";
import { IdeaPitchResponseDetailComponent } from "./idea-pitch-response-detail/idea-pitch-response-detail.component";
import { CreateIdeaPitchComponent } from "./create-idea-pitch/create-idea-pitch.component";
import { IdeaPitchResponseComponent } from "./idea-pitch-response/idea-pitch-response.component";
import { IdeaPitchService } from "src/app/shared/services/ideapitch.service";

const routes: Routes = [
  {
    path: "",
    component: IndexComponent,
    data: { breadcrumb: "Idea Pitch" },
    children: [
      {
        path: "",
        pathMatch: "full",
        data: { breadcrumb: "" },
        component: PitchsComponent,
      },
      {
        path: "acknowledgement",
        data: { breadcrumb: "Acknowledgement" },
        component: AcknowledgementComponent,
      },
      {
        path: "create-idea-pitch",
        data: { breadcrumb: "Create Idea Pitch" },
        component: CreateIdeaPitchComponent,
      },
      {
        path: "create-idea-pitch/:id",
        component: CreateIdeaPitchComponent,
        data: { breadcrumb: (data: any) => `${data?.ideapitch?.title}` },
        resolve: { ideapitch: IdeaPitchService },
      },
      {
        path: "idea-pitch-detail",
        data: { breadcrumb: "Idea pitch Detail" },
        component: IdeaPitchResponseDetailComponent,
      },
      {
        path: "idea-pitch-response/:id",
        component: IdeaPitchResponseComponent,
        data: { breadcrumb: (data: any) => `${data?.ideapitch?.title}` },
        resolve: { ideapitch: IdeaPitchService },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IdeaPitchRoutingModule {}
