<div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
  </div>
  <div class="bg-color p-3">

<div class="px-0 pb-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
      <div class="single-left-pane">
          <app-side-menu></app-side-menu>
      </div>
  </div>
  <div class="col-sm-7">
      <div class="single-center-pane">
        <router-outlet></router-outlet>
          
          
      </div>
  </div>
  <div class="col-sm-3">
      <div class="single-right-pane">
          <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
          <app-gnews></app-gnews>
          <!-- <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                    style="font-size: 13px">
                    Curated from Web
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body p-2">
                    <app-loading *ngIf="loading"></app-loading>
                    <app-gnews [display_header]="'false'"></app-gnews>
                </div>
            </div>
        </div>  -->
      </div>
  </div>
</div>

    <!-- <router-outlet></router-outlet> -->
  </div>
  <app-footer></app-footer>