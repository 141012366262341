import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdeaPitchService {

  constructor(private http: HttpClient,private transferState: TransferState, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let id = route.params['id'];
    try{
      id = atob(id)
    }catch(e){
      this.router.navigate(["404"])
    }    
    const detailKey = makeStateKey(`ideapitch/idea_pitch/${id}/`);
    return this.http.get(`ideapitch/idea_pitch/${id}/`).pipe(
      tap((res:any) => {
        this.transferState.set(detailKey, res)
      })
    )
  }

  getIdeaPitches(tagFilterId:any){
    let tag='';
    if(tagFilterId?.length > 0){
      tag = "tag_ids="+[tagFilterId];
    }
    return this.http.get(`ideapitch/idea_pitch/?${tag}`)
  }

  getIdeaPitch(id: any){
    return this.http.get(`ideapitch/idea_pitch/${id}/`)
  }
  
  createIdeaPitch(payload:any){
    return this.http.post(`ideapitch/idea_pitch/`, payload)
  }

  updateIdeaPitch(id: any, payload:any){
    return this.http.patch(`ideapitch/idea_pitch/${id}/`, payload)
  }

  deleteIdeaPitchFile(id: any){
    return this.http.delete(`ideapitch/ideapitch_files/${id}/`)
  }

  getServiceProvider(){
    return this.http.get(`ideapitch/ideapitch_serviceprovider/`)
  }

  createResponse(payload: any){
    return this.http.post(`ideapitch/ideapitch_response/`, payload)
  }

  postComments(formData:any){
    return this.http.post(`ideapitch/ideapitch_comments/`,formData);
  }

  getResponseComments(id: any){
    return this.http.get(`ideapitch/ideapitch_comments/?response_id=${id}`);
  }

  getAllServiceProviderList(){
    return this.http.get(`vendor/`);
  }

  moveToAccelerator(payload: any){
    return this.http.post(`accelerators/accelerators/`, payload);
  }

  deletePitch(id: any){
    return this.http.delete(`ideapitch/idea_pitch/${id}/`)
  }
}
