import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/shared/services/message.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  currentYear:any;
  loading:any = false;
  staticText: any = (textConfiguration as any).default;
  constructor(
    private messageService: MessageService
  ) { }
  

  ngOnInit(): void {
    this.currentYear=new Date().getFullYear();
    this.messageService.setGnews('Idea Pitch listing (All, Draft, Published, Shortlist, Rejected)')
  }

}
