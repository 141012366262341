import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-idea-pitch-response-detail',
    templateUrl: './idea-pitch-response-detail.component.html',
    styleUrls: ['./idea-pitch-response-detail.component.scss']
})
export class IdeaPitchResponseDetailComponent implements OnInit {
    statements: any;
    panelOpenState: boolean = true;
    status: string = 'Draft';

    constructor(private router: Router) { }

    ngOnInit(): void {

    }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }

    getGMapRandomColor() {
        return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
    }

    statusChange(s: any){
        this.status = s;
    }

}
