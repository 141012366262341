import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { IdeaPitchService } from 'src/app/shared/services/ideapitch.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ProgramService } from 'src/app/shared/services/program.service';

@Component({
  selector: 'app-create-idea-pitch',
  templateUrl: './create-idea-pitch.component.html',
  styleUrls: ['./create-idea-pitch.component.scss']
})
export class CreateIdeaPitchComponent implements OnInit {
  okr: any;
  panelOpenState = false;
  panelOpenState1 = false;
  ideaId: any;
  ideaData: any;
  
  newForm: any;
  linkURL: any;

  serviceProviders: any = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  options2: any;
  filteredOptions2: any;
  options1: any;
  filteredOptions1: any;
  subPortOptions: any;
  ownersOptions: any;
  programOptions: any;
  programFilteredOptions: any;
  filterOptionType: Observable<any[]> | any;
  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  filterOptionOwners: Observable<any[]> | any;
  filterOptionProgram: Observable<any[]> | any;
  subPortfilteredOptions: any;
  subPortFolioChild:any;
  ownerDetails:any;


  constructor(private router: Router, private location: Location, private fb: FormBuilder,
    private ideaPitchService: IdeaPitchService, private spinner: NgxSpinnerService, private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,  private sanitizer: DomSanitizer,private messageService: MessageService,
    private ps: ProgramService) { }
  

  ngOnInit(): void {
    this.messageService.setGnews('Idea Pitch creation')
    this.activatedRoute.params.subscribe(params => {
      if(params.id){
        this.ideaId = atob(params.id);
        this.getIdeaById()
      }
    });
    this.getAllServiceProviderList();
    this.getList();
    this.newForm = this.fb.group({
      id: [''],
      title: ['', Validators.required],
      description: ['', Validators.required],
      links: this.fb.array([]),
      status: ['', Validators.required],
      files: this.fb.array([]),
      tag_ids: [],
      vendor_id: [''],
      impact:['',Validators.required],
      technologies:['',Validators.required],
      poc_expectations:['',Validators.required],
      portfolio:['',Validators.required],
      subPortfolio:['',Validators.required],
      program:['',Validators.required],
      owners:['',Validators.required],
      owner_id:[''],
    });
  }

  getAllServiceProviderList(){
    this.ideaPitchService.getAllServiceProviderList().subscribe(async (res:any)=>{
      if(res){
        res.forEach((element: any) => {
          element.url = element.url ? element.url : ""
        });
        this.serviceProviders = res;
      }
    }); 
  }


  getIdeaById(){
    this.ideaPitchService.getIdeaPitch(this.ideaId).subscribe((res: any) => {
      this.spinner.hide();
      this.ideaData = res;
      this.newForm.patchValue(res);

      res?.links.forEach((link:any) => {
        this.linksControls.push(this.createLinksControl(link));
      });

      if(res.vendor){
        this.newForm.get('vendor_id').setValue(res.vendor.id)
      }
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error("Error while fetching the idea...");
      throw error;
    });
  }

  get linksControls() {
    return (this.newForm.get('links') as FormArray);
  }

  createLinksControl(link:any): FormControl {
    return this.fb.control(link);
  }

  async getList(type?: any) {
    //this.spinner.show();
   
    combineLatest([

      this.ps.getOpportunityType().pipe(tap((res:any) => {
        this.options2 = res.opportunity_type;
        this.filteredOptions2 = this.options2;      
      })),
   
      this.ps.getOpportunityHierarchy().pipe(tap((res:any) => {
        this.options1 = res.records;
        this.filteredOptions1 = this.options1;
            
      })),
    
      this.ps.getProgramData().pipe(tap((res:any) => {
        this.programOptions = res;
          this.programFilteredOptions = this.programOptions;
        
            
      })
      )
   
   ]).subscribe((resultArray) => {
    this.initAutoComplete()

   })
  }

  initAutoComplete(): any {
    
  this.filterOptionPortfolio = this.newForm.get('portfolio')!.valueChanges
    .pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.name),
      map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
    );
    this.filterOptionProgram = this.newForm.get('program')!.valueChanges
    .pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.name),
      map((name: any) => name ? this.programFilter(name) : this.programFilteredOptions?.slice())
    );   
  // this.valuePatch(this.patchResponse);
  }

  private portfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions1?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private programFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.programFilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue))
  }

  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.newForm.patchValue({
      subPortfolio: '',
      owners:'',
      owner_id:''
    })
    
    
    this.subPortOptions = event.source.value.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.newForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
    );
  }  

  onSelectionChangeSubportPortfolio(e: any) {    
    const subportFolioId = e.source.value.id;
    if (!subportFolioId) {
      return;
    }  
    let value = e.source.value;  
    this.subPortFolioChild =[];
    this.ownerDetails = value.owner_details;
      this.newForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username,
        owner_id:value.owner_details?.[0]?.id
      })
  }


  submitData(type:any){
    this.newForm.get('status').setValue(type);
    if(this.newForm.invalid){
      this.toastrService.error("Please fill the mandatory input")
      return;
    }
   
    this.spinner.show();
    
    let dataFiles:any=[]
    const formData = new FormData();
    formData.append('title',this.newForm.value.title);
    formData.append('description',this.newForm.value.description);
    formData.append('status',type);
    formData.append('links', this.newForm.value.links);
    formData.append('vendor_id', this.newForm.value.vendor_id);
    this.newForm.get('files').value.forEach((file:any) => { formData.append('files', file)});
    formData.append('impact',this.newForm.value.impact);
    formData.append('technologies',this.newForm.value.technologies);
    formData.append('poc_expectations',this.newForm.value.poc_expectations);
    formData.append('portfolio_id',this.newForm.value.subPortfolio.id);
    formData.append('program_id',this.newForm.value.program.id);
    formData.append('owner_id',this.newForm.value.owner_id);

    if(this.newForm.value.id == ""){
      this.createIdea(formData, type);
    }else{
      this.updateIdea(formData, type);
    }
  }

  createIdea(formData:any, type: any){
    this.ideaPitchService.createIdeaPitch(formData).subscribe(async (res:any)=>{
      if(res){
        this.spinner.hide();
        this.toastrService.success("Idea Saved Successfully...");
        this.router.navigateByUrl('ideapitch/create-idea-pitch/' + btoa(res.id))
      }
    });
  }
  updateIdea(formData:any, type: any){
    this.ideaPitchService.updateIdeaPitch(this.ideaData?.id, formData).subscribe(async (res:any)=>{
      if(res){
        this.spinner.hide();
        if(type == 'draft'){
          this.toastrService.success("Idea Updated Successfully.")
        }else{
          this.toastrService.success("Idea Published Successfully.")
          this.router.navigateByUrl('ideapitch/idea-pitch-response/' + btoa(res.id))
        }
       
      }
    });
  }

  DeleteFile(fileId:any){
    this.spinner.show();
    this.ideaPitchService.deleteIdeaPitchFile(fileId).subscribe((res:any)=>{
        this.spinner.hide();
        this.ideaData.idea_pitch_files = this.ideaData?.idea_pitch_files?.filter((item:any) => item.id != fileId);
        this.toastrService.success("File Deleted Successfully.")
    });
  }

  onFileSelected(event:any){
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error('Please upload less than 5MB');
        return;
      }
     this.addFile(file);
    }
  }

  addFile(file:any) {
    this.fileControls.push(this.createFileControl(file));
  }

  createFileControl(file:any): FormControl {
    return this.fb.control(file);
  }

  get fileControls() {
    return (this.newForm.get('files') as FormArray);
  }

  displayFnName(data: any) {
    return data && data.name ? data?.name : data?.full_name;
  }

  removeFile(index: number) {
    this.fileControls.removeAt(index);
  }

  getType(x:any){
    const ext = x.substring(x.lastIndexOf('.') + 1);
    return ext?.toLowerCase()
  }

  addLink(){
    this.newForm.value.links.push(this.linkURL);
    this.linkURL = "";
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 100%, 40%, 80%)';
  } 

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  getTagObject(){
    return {
      entity_id: this.ideaId,
      entity_type: "ideapitch"
    }
  }

  goBack() {
    this.location.back();
  }

  openLink(x: any){
    window.open(x, '_blank')
  }

  removeLink(i: any){
    this.newForm.value.links.splice(i, 1)
  }
}
