<h6>Acknowledgement</h6>
<div class="ack-hold">
    <div>
        We require your consent before enabling this feature, as it allows not only 
        empanelled vendors but also the general public to view and submit 
        their design ideas in the Challenge Workbench event, which is a public entity.

        <button mat-button color="success" aria-label="add" style="display: block; margin: auto" class="mt-4" (click)="navigateTo('ideapitch/pitchs')">
            Accept
        </button>
    </div>
</div>