<app-loading *ngIf="!ideaData"></app-loading>
<div *ngIf="ideaData">
    <div class="status text-capitalize">{{ideaData?.status}}</div>
    <div class="row mb-3">
        <div class="col-sm-7" style="display: flex; justify-content: left; align-items: center">
            <h5 class="m-0">{{ideaData?.title}}</h5>
        </div>
        <div class="col-sm-5" style="display: flex; justify-content: right; align-items: center">
            <button mat-flat-button color="primary" aria-label="add" class="mr-1" (click)="changeStatus('shortlist')" *ngIf="ideaData?.status == 'publish' && ideaData?.response_id != null">
                Shortlist
            </button>
            <button mat-flat-button color="warn" aria-label="add" class="ml-1" (click)="changeStatus('reject')" *ngIf="ideaData?.status == 'publish' && ideaData?.response_id != null">
                Reject
            </button>
            <button mat-flat-button color="move" aria-label="add" class="ml-1" (click)="moveToDeliveryAccelerator()" *ngIf="ideaData?.status == 'shortlist' && ideaData?.response_id != null">
                Move to Delivery Accelerator
            </button>
        </div>
    </div>


    <mat-accordion *ngIf="ideaData">
        <mat-expansion-panel (opened)="panelOpenState2 = true" [expanded]="true" (closed)="panelOpenState2 = false"
            style="border-radius: 20px;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="text-center"> View your submission </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="padding: 20px; overflow-x: scroll">
                <div style="margin-bottom: 20px">
                    <img style="width: 50px; height: 50px; object-fit: cover;" src="{{ideaData?.vendor?.url}}"> {{ideaData?.vendor.name}}
                </div>
                <app-tag  [dataObj]="getTagObject()"  [tagsIds]="ideaData?.tag_ids"></app-tag>
                <p style="font-size: 14px; margin-top: 15px; text-align: justify;">
                    {{ideaData?.description}}
                </p>
                <mat-accordion>
                    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false" style="border-radius: 20px;">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Attachments
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="padding: 20px; overflow-x: scroll">
                            <div class="d-flex flex-row flex-nowrap overflow-auto" style="padding: 10px 0">
                                <div class="card card-block mx-2" style="width: 150px; height: 150px; display: flex; 
                                                    align-items: center; justify-content: center; border-radius: 15px;"
                                    *ngFor="let x of ideaData?.idea_pitch_files; let i = index">
                                    <div class="mr-2 mt-2">
                                        <img *ngIf="x?.file_type == 'doc' || x?.file_type == 'docx'"
                                            src="./assets/images/word.svg" height="40">
                                        <img *ngIf="x?.file_type == 'pdf'" src="./assets/images/pdf.svg" height="40">
                                        <img *ngIf="x?.file_type == 'xls' || x?.file_type == 'xlsx'"
                                            src="./assets/images/excel.svg" height="40">
                                        <img *ngIf="x?.file_type == 'csv'" src="./assets/images/csv.png" height="40">
                                        <mat-icon
                                            *ngIf="x?.file_type !== 'pdf' && x?.file_type !== 'doc' && x?.file_type !== 'docx' && x?.file_type !== 'xls' && x?.file_type !== 'xlsx' && x?.file_type !== 'csv'"
                                            mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                            description</mat-icon>
                                    </div>
                                    <p class="m-0">{{x?.file_name}} </p>
                                </div>
                                <div class="card card-block mx-2" style="min-width: 150px; height: 150px; display: flex; 
                                align-items: center; justify-content: center; border-radius: 15px;"
                                    *ngFor="let x of ideaData?.links; let i = index" (click)="openLink(x)">
                                    <mat-icon>link</mat-icon>
                                    <p class="m-0"> {{x}} </p>
                                </div>
                                <div *ngIf="ideaData?.links.length == 0 && ideaData?.idea_pitch_files.length == 0">
                                    Attachment or link not found</div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="text-center">
        <button *ngIf="ideaData?.response_id == null" (click)="createResponse()" class="mr-2 ml-2 mt-3" mat-flat-button color="primary">Create Response
        </button>
    </div>
    <div class="p-3">
        <app-comments *ngIf="ideaData?.response_id != null"(dataEvent)="receiveDataFromComment($event)" (downloadFileEvent)="downloadCommentFile($event)" [comments]="responseComment" [refresh]="refreshComment"></app-comments>
    </div>
</div>