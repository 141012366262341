import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IdeaPitchService } from 'src/app/shared/services/ideapitch.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-pitchs',
  templateUrl: './pitchs.component.html',
  styleUrls: ['./pitchs.component.scss']
})
export class PitchsComponent implements OnInit {
  statements: any;
  draftStatements: any;
  publishedStatements: any;
  completedStatements: any;
  shortlistedStatements: any;
  rejectedStatements: any;
  user: any;
  subscription:any;
  filterObj:any;
  tagFilterId:any;
  
  constructor(private router: Router, private ideaPitchService: IdeaPitchService, 
    private spinner: NgxSpinnerService,    private filterService: FilterService,
    private toastrService: ToastrService) { }
  

  ngOnInit(): void {
    if(localStorage.getItem('token')){
      this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    }
    this.subscription = this.filterService.filterSubject.subscribe((res: any) => {
      this.filterObj = res;
      this.tagFilterId=[];
      Object.keys(this.filterObj).forEach((element: any) => {
        if(element == 'tag'){
          console.log(this.filterObj[element]);
          this.filterObj[element]?.array?.forEach((tag:any)=>{
            this.tagFilterId.push(tag?.id);
          })
        };
      });
      this.getIdeas();
    })
   
  }


  getIdeas(){
    this.spinner.show();
    this.statements =[];
    this.draftStatements = [];
    this.publishedStatements = [];
    this.completedStatements =[];
    this.shortlistedStatements = [];
    this.rejectedStatements = [];
    this.ideaPitchService.getIdeaPitches(this.tagFilterId).subscribe((res: any) => {
      this.spinner.hide();
      res.forEach((element: any) => {
        element.color = this.getGMapRandomColor(),
        element.updated_at = new Date(element.last_updated_on)
      });
      this.statements = res;
      this.draftStatements = res.filter((e: any) => e.status == 'draft')
      this.publishedStatements = res.filter((e: any) => e.status == 'publish')
      this.completedStatements = res.filter((e: any) => e.status == 'completed')
      this.shortlistedStatements = res.filter((e: any) => e.status == 'shortlist')
      this.rejectedStatements = res.filter((e: any) => e.status == 'reject')
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    });
  }

  getTagObject(x: any){
    return {
      entity_id: x.id,
      entity_type: "ideapitch"
    }
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 30%, 40%, 100)';
  } 

  openDetail(x: any){
    if(x.status == 'draft'){
      this.router.navigateByUrl('ideapitch/create-idea-pitch/' + btoa(x.id))
    }else{
      this.router.navigateByUrl('ideapitch/idea-pitch-response/'+ btoa(x.id))
    }
  }

  editIdea(x: any){
    this.router.navigateByUrl('ideapitch/create-idea-pitch/' + btoa(x.id))
  }

  ngOnDestroy(): void{
    this.subscription.unsubscribe();
  }
  getName(name: any) {
    return name ? name.split(' ').slice(0, 2).map((n: any) => n[0]).join('') : name;
  }

  deletePitch(id: any, i: any) {
    this.ideaPitchService.deletePitch(id).subscribe((res: any) => {
      this.statements.splice(i, 1)
      this.toastrService.success("Ideapitch deleted successfully");
    })
  }
}
