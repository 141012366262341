import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IdeaPitchService } from 'src/app/shared/services/ideapitch.service';

@Component({
    selector: 'app-idea-pitch-response',
    templateUrl: './idea-pitch-response.component.html',
    styleUrls: ['./idea-pitch-response.component.scss']
})
export class IdeaPitchResponseComponent implements OnInit {
    statements: any;
    panelOpenState: boolean = true;
    panelOpenState1: boolean = true;
    panelOpenState2: boolean = true;
    ideaId: any;
    ideaData: any;
    refreshComment:boolean = false;
    responseComment:any = [];
    selectedResponseId: any;

    constructor(private router: Router, private location: Location,
        private activatedRoute: ActivatedRoute, private ideaPitchService: IdeaPitchService,
        private spinner: NgxSpinnerService, private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if(params.id){
              this.ideaId = atob(params.id);
              this.getIdeaById()
            }
        });
    }

    getIdeaById(){
        this.ideaPitchService.getIdeaPitch(this.ideaId).subscribe((res: any) => {
          this.spinner.hide();
          this.ideaData = res;
          this.selectedResponseId = res.response_id;
          if(res.response_id != null){
            this.getResponseComment(res.response_id)
          }
        }, (error: any) => {
          this.spinner.hide();
          this.toastrService.error("Error while fetching the idea...");
          throw error;
        });
      }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }

    getGMapRandomColor() {
        return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
    }

    goBack(){
        this.location.back();
    }

    openLink(x: any){
        window.open(x, '_blank')
    }

    createResponse(){
        let payload = {
            idea_pitch_id: this.ideaId,
            status: "draft"
        }
        this.ideaPitchService.createResponse(payload).subscribe((res: any) => {
            this.spinner.hide();
            this.toastrService.success("Tenant added to response");
            this.getIdeaById();
          }, (error: any) => {
            this.spinner.hide();
            this.toastrService.error("Error while adding tenant response...");
            throw error;
          });
    }

    getResponseComment(id: any){
        this.selectedResponseId = id;
        this.ideaPitchService.getResponseComments(id).subscribe((res:any)=>{
            this.responseComment = res;
        })
    }


    receiveDataFromComment(event:any){
        this.spinner.show();
        this.refreshComment = false;
        const formData = new FormData();
        if(event?.text || event?.document){
            if(event.text)
            formData.append('text',event.text);
            if(event.document){
            formData.append('document',event.document);
            }
            formData.append('response_id',this.selectedResponseId);
            formData.append('parent', null!);
            this.ideaPitchService.postComments(formData).subscribe((res: any) => {
                this.responseComment.push(res);
                this.refreshComment = true;
                this.spinner.hide();
            })
        }   
    }

    downloadCommentFile(event:any){
        this.spinner.show();
        // this.cw.downloadCommentFile(event.id).subscribe((res: any) => {
        // let downloadLink = document.createElement('a');
        // downloadLink.href = window.URL.createObjectURL(res);
        // downloadLink.setAttribute('download', event.file_name);
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // this.toastrService.success('File downloaded successfully');
        // this.spinner.hide();
        // }, (error: any) => {
        // this.spinner.hide();
        // throw error;
        // })
    }

    changeStatus(status: any){
        let payload = {
            status: status
        }
        this.ideaPitchService.updateIdeaPitch(this.ideaId, payload).subscribe((res: any) => {
            this.spinner.hide();
            this.toastrService.success("Status updated successfully");
            this.getIdeaById();
          }, (error: any) => {
            this.spinner.hide();
            this.toastrService.error("Error while updating status...");
            throw error;
          });
    }

    moveToDeliveryAccelerator(){
        let payload = {
            source_id: this.ideaId,
            source_type: 'ideapitch',
            status: "under_commercials"
        }
        this.ideaPitchService.moveToAccelerator(payload).subscribe((res: any) => {
            this.spinner.hide();
            this.getIdeaById();
            this.toastrService.success("Idea moved to delivery accelerator successfully");
          }, (error: any) => {
            this.spinner.hide();
            this.toastrService.error("Error while moving to delivery accelerator...");
            throw error;
          });
    }

    getTagObject(){
        return {
          entity_id: this.ideaId,
          entity_type: "ideapitch"
        }
      }
}
