<div>
  <div class="row">
    <div class="col-sm-6 trn-trc">
      <h6>Ideapitch Creation</h6>
    </div>
  </div>
  <form [formGroup]="newForm">
    <div class="row mt-3">
      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="w-100 px-1">
          <mat-label>Ideapitch Title</mat-label>
          <input matInput formControlName="title" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 px-1">
          <mat-label>Description</mat-label>
          <textarea matInput rows="4" formControlName="description"></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 px-1">
          <mat-label>Vendor</mat-label>
          <mat-select formControlName="vendor_id">
            <mat-option *ngFor="let x of serviceProviders" [value]="x.id">
              <img
                src="{{ x.url }}"
                style="
                  width: 35px;
                  height: 35px;
                  object-fit: cover;
                  margin-right: 10px;
                "
              />{{ x.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-50 pr-2">
          <mat-label>Portfolio</mat-label>
          <input
            matInput
            [matAutocomplete]="auto"
            type="text"
            formControlName="portfolio"
          />
          <mat-icon matSuffix class="muted">search</mat-icon>
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFnName"
          >
            <mat-option
              *ngFor="let option of filterOptionPortfolio | async"
              [value]="option"
              (onSelectionChange)="onSelectionChangePortfolio($event)"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-50 pl-2">
          <mat-label>Sub Portfolio</mat-label>
          <input
            matInput
            [matAutocomplete]="auto1"
            type="text"
            formControlName="subPortfolio"
          />
          <mat-icon matSuffix class="muted">search</mat-icon>
          <mat-autocomplete
            #auto1="matAutocomplete"
            [displayWith]="displayFnName"
          >
            <mat-option
              *ngFor="let option of filterOptionSubPortfolio | async"
              [value]="option"
              (onSelectionChange)="onSelectionChangeSubportPortfolio($event)"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-50 pr-2">
          <mat-label>Program</mat-label>
          <input
            matInput
            [matAutocomplete]="auto5"
            type="text"
            formControlName="program"
          />
          <mat-icon matSuffix class="muted">search</mat-icon>
          <mat-autocomplete
            #auto5="matAutocomplete"
            [displayWith]="displayFnName"
          >
            <mat-option
              *ngFor="let option of filterOptionProgram | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-50 pl-2">
          <mat-label>Owner</mat-label>
          <input matInput type="text" readonly formControlName="owners" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-50 pr-2">
          <mat-label>Impact</mat-label>
          <input matInput formControlName="impact" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-50 pl-2">
          <mat-label>Technology stack /preferences</mat-label>
          <input matInput formControlName="technologies" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>POC expectations</mat-label>
          <textarea
            matInput
            rows="3"
            formControlName="poc_expectations"
          ></textarea>
        </mat-form-field>

        <div *ngIf="ideaData">
          <app-tag
            [dataObj]="getTagObject()"
            [tagsIds]="ideaData.tag_ids"
          ></app-tag>
        </div>

        <h6 class="mt-2">Attach File</h6>
        <mat-accordion>
          <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            style="border-radius: 20px"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Attachments ({{ fileControls.controls.length }})

                <button mat-icon-button color="success" class="bwo">
                  <input
                    hidden
                    #fileInput
                    type="file"
                    id="file"
                    (change)="onFileSelected($event)"
                  />
                  <mat-icon (click)="fileInput.click()">add</mat-icon>
                </button>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="padding: 20px; overflow-x: scroll">
              <div
                class="d-flex flex-row flex-nowrap overflow-auto"
                style="padding: 10px 0"
              >
                <div
                  class="card card-block mx-2"
                  style="
                    width: 150px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                  "
                  *ngFor="let x of ideaData?.idea_pitch_files; let i = index"
                >
                  <div class="mr-2 mt-2">
                    <img
                      *ngIf="x?.file_type == 'doc' || x?.file_type == 'docx'"
                      src="./assets/images/word.svg"
                      height="40"
                    />
                    <img
                      *ngIf="x?.file_type == 'pdf'"
                      src="./assets/images/pdf.svg"
                      height="40"
                    />
                    <img
                      *ngIf="x?.file_type == 'xls' || x?.file_type == 'xlsx'"
                      src="./assets/images/excel.svg"
                      height="40"
                    />
                    <img
                      *ngIf="x?.file_type == 'csv'"
                      src="./assets/images/csv.png"
                      height="40"
                    />
                    <mat-icon
                      *ngIf="
                        x?.file_type !== 'pdf' &&
                        x?.file_type !== 'doc' &&
                        x?.file_type !== 'docx' &&
                        x?.file_type !== 'xls' &&
                        x?.file_type !== 'xlsx' &&
                        x?.file_type !== 'csv'
                      "
                      mat-list-icon
                      class="material-icons my-auto fs-1 text-danger"
                    >
                      description</mat-icon
                    >
                  </div>
                  <p class="m-0">{{ x?.file_name }}</p>
                  <mat-icon color="remove" (click)="DeleteFile(x?.id)"
                    >clear</mat-icon
                  >
                </div>
                <div
                  class="card card-block mx-2"
                  style="
                    width: 150px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                  "
                  *ngFor="let x of fileControls.controls; let i = index"
                >
                  <div class="mr-2 mt-2">
                    <img
                      *ngIf="
                        getType(x?.value?.name) == 'doc' ||
                        getType(x?.value?.name) == 'docx'
                      "
                      src="./assets/images/word.svg"
                      height="40"
                    />
                    <img
                      *ngIf="getType(x?.value?.name) == 'pdf'"
                      src="./assets/images/pdf.svg"
                      height="40"
                    />
                    <img
                      *ngIf="
                        getType(x?.value?.name) == 'xls' ||
                        getType(x?.value?.name) == 'xlsx'
                      "
                      src="./assets/images/excel.svg"
                      height="40"
                    />
                    <img
                      *ngIf="getType(x?.value?.name) == 'csv'"
                      src="./assets/images/csv.png"
                      height="40"
                    />
                    <mat-icon
                      *ngIf="
                        getType(x?.value?.name) !== 'pdf' &&
                        getType(x?.value?.name) !== 'doc' &&
                        getType(x?.value?.name) !== 'docx' &&
                        getType(x?.value?.name) !== 'xls' &&
                        getType(x?.value?.name) !== 'xlsx' &&
                        getType(x?.value?.name) !== 'csv'
                      "
                      mat-list-icon
                      class="material-icons my-auto fs-1 text-danger"
                    >
                      description</mat-icon
                    >
                  </div>
                  <p class="m-0" style="width: 60%; text-align: center">
                    {{ x?.value?.name }}
                  </p>
                  <mat-icon color="remove" (click)="removeFile(i)"
                    >clear</mat-icon
                  >
                </div>
                <div
                  *ngIf="
                    fileControls.controls.length == 0 &&
                    ideaData?.idea_pitch_files.length == 0
                  "
                >
                  No file attached
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <h6 class="mt-4">Link</h6>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field appearance="outline" class="w-100 px-1">
              <mat-label>Link URL</mat-label>
              <input
                matInput
                [(ngModel)]="linkURL"
                [ngModelOptions]="{ standalone: true }"
              />
            </mat-form-field>
          </div>
          <div
            class="col-sm-1"
            style="display: flex; justify-content: space-between"
          >
            <button mat-icon-button color="success" (click)="addLink()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <mat-accordion>
          <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            style="border-radius: 20px"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Links ({{ newForm.value.links.length }})
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: scroll">
              <div
                class="d-flex flex-row flex-nowrap overflow-auto"
                style="padding: 10px"
              >
                <div
                  class="card card-block mx-2"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    border-radius: 15px;
                    cursor: pointer;
                  "
                  *ngFor="let x of newForm.value.links; let i = index"
                  (click)="openLink(x)"
                >
                  <div class="d-flex align-items-center">
                    <mat-icon class="lk">link</mat-icon>
                    <p class="m-0 asw">{{ x }}</p>
                    <mat-icon color="remove" (click)="removeLink(i)"
                      >clear</mat-icon
                    >
                  </div>
                </div>
                <div *ngIf="newForm.value.links.length == 0">
                  No links added
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </form>
  <div class="d-flex justify-content-end mt-4">
    <button
      mat-button
      mat-stroked-button
      color="primary"
      aria-label="add"
      class="mr-1"
      (click)="submitData('draft')"
    >
      Save as Draft
    </button>
    <button
      mat-button
      mat-flat-button
      color="primary"
      aria-label="add"
      class="bg-primary text-white ml-1"
      (click)="submitData('publish')"
    >
      Publish Idea
    </button>
  </div>
</div>
