<div class="row mb-2">
    <div class="col-sm-6 trn-trc">
        <h5>Idea Pitch </h5>
    </div>
    <div class="col-sm-6 trn-trc-1">
        <button mat-button mat-flat-button color="primary" class="bg-primary text-white ml-2" aria-label="add" (click)="navigateTo('ideapitch/create-idea-pitch')">
            <mat-icon>add</mat-icon> Create Idea Pitch
        </button>
    </div>
</div>
<div class="row mt-2">
    <div class="col-sm-8">
        <div>
            <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
        </div>
    </div>
    <!-- <div class="col-sm-4 trn-tc-2">
        <input type="text" placeholder="Search ..." class="form-control">
    </div> -->
</div>
<mat-tab-group mat-align-tabs="start">
    <mat-tab label="All ({{statements?.length}})">
        <div style="overflow-x: hidden;">
            
            <div class="tab-holder">
                <div class="flex-container-1">
                    <div *ngIf="statements?.length <= 0">
                        <p class="notfound">No record found</p>
                    </div>
                    <div class="flex-item p-1" *ngFor="let x of statements;">
                        <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                            <div class="d-flex justify-content-between">
                                <h6 class="mb-0 trn-nm" style="width: 80%; font-size: 12px">{{x.title}}</h6>
                                <span class="tag-icon"  *ngIf="x?.tag_ids?.length > 0" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" > <i class="far fa-tag" style="font-size: 10px;"></i></span>
                            </div>
                            <mat-menu #menu="matMenu" class="my-class">
                                <app-tag [tagsIds]="x?.tag_ids"></app-tag>
                            </mat-menu>
                            <p class="desc">{{x.description}}</p>
                            <div>
                                <p class="m-0 mt-1" style="font-size: 8px;">Submitted by</p>
                                <div class="m-0 d-flex" style="font-size: 12px; font-weight: 800;">
                                    <div>
                                        <img *ngIf="x?.vendor?.url" src="{{x?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                                        <span class="name-1" *ngIf="!x?.vendor?.url"   [ngStyle]="{'backgroundColor': x?.vendor?.url}">{{getName(x.vendor.name)}}</span>
                                    </div>
                                    <h6 class="mb-0 trn-nm ml-2 mt-2">{{x?.vendor?.name}}</h6>
                                </div>
                            </div>
                            <!-- <div style="padding: 10px; text-align: center; margin: 0 -15px;">
                                <app-tag [dataObj]="getTagObject(x.id)" [tagsIds]="x?.tag_ids" [slices]="1"></app-tag>
                            </div> -->
                            <div class="st-col" [ngStyle]="{'background': x.status == 'draft' ? '#d38128' : x.status == 'publish' ? 'blue' : x.status == 'shortlist' ? 'green' : x.status == 'reject' ? 'darkred' : '#737373'}">
                                {{x.status}}</div>
                           
                        </div>
                        <div class="dos">
                            <span style="font-size: 10px">Updated {{x.updated_at | timeAgo}}</span>

                        </div>

                        <button mat-icon-button class="action-vert" color="warn" (click)="deletePitch(x.id, i); $event.stopPropagation()" *ngIf="x.status == 'draft'" >
                            <mat-icon style="font-size: 15px; color: red">delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Draft ({{draftStatements?.length}})">
        <div style="overflow-x: hidden;">
            <!-- <div class="row mt-2">
                <div class="col-sm-8">
                    <div style="overflow-x: scroll">
                        <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
                    </div>
                </div>
                <div class="col-sm-4 trn-tc-2">
                    <input type="text" placeholder="Search ..." class="form-control">
                </div>
            </div> -->
            <div class="tab-holder" *ngIf="draftStatements">
                <div class="flex-container-1">
                    <div *ngIf="draftStatements?.length <= 0">
                        <p class="notfound">No record found</p>
                    </div>
                    <div class="flex-item p-1" *ngFor="let x of draftStatements;">
                        <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                           <div class="d-flex justify-content-between">
                                <h6 class="mb-0 trn-nm" style="width: 80%; font-size: 12px">{{x.title}}</h6>
                                <span class="tag-icon"  *ngIf="x?.tag_ids?.length > 0" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" > <i class="far fa-tag" style="font-size: 10px;"></i></span>
                            </div>
                            <mat-menu #menu="matMenu" class="my-class">
                                <app-tag [tagsIds]="x?.tag_ids"></app-tag>
                            </mat-menu>
                            <p class="desc">{{x.description}}</p>
                            <div>
                                <p class="m-0 mt-1" style="font-size: 8px;">Submitted by</p>
                                <div class="m-0 d-flex" style="font-size: 12px; font-weight: 800;">
                                    <div>
                                        <img *ngIf="x?.vendor?.url" src="{{x?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                                        <span class="name-1" *ngIf="!x?.vendor?.url"   [ngStyle]="{'backgroundColor': x?.vendor?.url}">{{getName(x.vendor.name)}}</span>
                                    </div>
                                    <h6 class="mb-0 trn-nm ml-2 mt-2">{{x?.vendor?.name}}</h6>
                                </div>
                            </div>
                            <!-- <div style="padding: 10px; text-align: center; margin: 0 -15px;">
                                <app-tag [dataObj]="getTagObject(x.id)" [tagsIds]="x?.tag_ids" [slices]="1"></app-tag>
                            </div> -->
                            <div class="st-col" [ngStyle]="{'background': x.status == 'draft' ? '#d38128' : x.status == 'publish' ? 'blue' : x.status == 'shortlist' ? 'green' : x.status == 'reject' ? 'darkred' : ''}">
                                {{x.status}}</div>
                        </div>
                        <div class="dos">
                            <span style="font-size: 10px">Updated {{x.updated_at | timeAgo}}</span>

                        </div>

                        <button mat-icon-button class="action-vert" color="warn" (click)="deletePitch(x.id, i); $event.stopPropagation()" *ngIf="x.status == 'draft'" >
                            <mat-icon style="font-size: 15px; color: red">delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Published ({{publishedStatements?.length}})">
        <div style="overflow-x: hidden;">
            <div class="tab-holder" *ngIf="publishedStatements">
                <div class="flex-container-1">
                    <div *ngIf="publishedStatements?.length <= 0">
                        <p class="notfound">No record found</p>
                    </div>
                    <div class="flex-item p-1" *ngFor="let x of publishedStatements;">
                        <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                            <img src="assets/images/edit.png" width="20px" (click)="editIdea(x); $event.stopPropagation()" *ngIf="x.status == 'draft'" style="position: absolute; top: 10px;  right: 10px;">
                            <div class="d-flex justify-content-between">
                                <h6 class="mb-0 trn-nm" style="width: 80%; font-size: 12px">{{x.title}}</h6>
                                <span class="tag-icon"  *ngIf="x?.tag_ids?.length > 0" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" > <i class="far fa-tag" style="font-size: 10px;"></i></span>
                            </div>
                            <mat-menu #menu="matMenu" class="my-class">
                                <app-tag [tagsIds]="x?.tag_ids"></app-tag>
                            </mat-menu>
                            <p class="desc">{{x.description}}</p>
                            <div>
                                <p class="m-0 mt-1" style="font-size: 8px;">Submitted by</p>
                                <div class="m-0 d-flex" style="font-size: 12px; font-weight: 800;">
                                    <div>
                                        <img *ngIf="x?.vendor?.url" src="{{x?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                                        <span class="name-1" *ngIf="!x?.vendor?.url"   [ngStyle]="{'backgroundColor': x?.vendor?.url}">{{getName(x.vendor.name)}}</span>
                                    </div>
                                    <h6 class="mb-0 trn-nm ml-2 mt-2">{{x?.vendor?.name}}</h6>
                                </div>
                            </div>
                            <!-- <div style="padding: 10px; text-align: center; margin: 0 -15px;">
                                <app-tag [dataObj]="getTagObject(x.id)" [tagsIds]="x?.tag_ids" [slices]="1"></app-tag>
                            </div> -->
                            <div class="st-col" [ngStyle]="{'background': x.status == 'draft' ? '#d38128' : x.status == 'publish' ? 'blue' : x.status == 'shortlist' ? 'green' : x.status == 'reject' ? 'darkred' : ''}">
                                {{x.status}}</div>                    
                        </div>
                        <div class="dos">
                            <span style="font-size: 10px">Updated {{x.updated_at | timeAgo}}</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Shortlist ({{shortlistedStatements?.length}})">
        <div style="overflow-x: hidden;">
            <div class="tab-holder" *ngIf="shortlistedStatements">
                <div class="flex-container-1">
                    <div *ngIf="shortlistedStatements?.length <= 0">
                        <p class="notfound">No record found</p>
                    </div>
                    <div class="flex-item p-1" *ngFor="let x of shortlistedStatements;">
                        <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                            <img src="assets/images/edit.png" width="20px" (click)="editIdea(x); $event.stopPropagation()" *ngIf="x.status == 'draft'" style="position: absolute; top: 10px;  right: 10px;">
                            <div class="d-flex justify-content-between">
                                <h6 class="mb-0 trn-nm" style="width: 80%; font-size: 12px">{{x.title}}</h6>
                                <span class="tag-icon"  *ngIf="x?.tag_ids?.length > 0" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" > <i class="far fa-tag" style="font-size: 10px;"></i></span>
                            </div>
                            <mat-menu #menu="matMenu" class="my-class">
                                <app-tag [tagsIds]="x?.tag_ids"></app-tag>
                            </mat-menu>
                            <p class="desc">{{x.description}}</p>
                            <div>
                                <p class="m-0 mt-1" style="font-size: 8px;">Submitted by</p>
                                <div class="m-0 d-flex" style="font-size: 12px; font-weight: 800;">
                                    <div>
                                        <img *ngIf="x?.vendor?.url" src="{{x?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                                        <span class="name-1" *ngIf="!x?.vendor?.url"   [ngStyle]="{'backgroundColor': x?.vendor?.url}">{{getName(x.vendor.name)}}</span>
                                    </div>
                                    <h6 class="mb-0 trn-nm ml-2 mt-2">{{x?.vendor?.name}}</h6>
                                </div>
                            </div>
                            <!-- <div style="padding: 10px; text-align: center; margin: 0 -15px;">
                                <app-tag [dataObj]="getTagObject(x.id)" [tagsIds]="x?.tag_ids" [slices]="1"></app-tag>
                            </div> -->
                            <div class="st-col" [ngStyle]="{'background': x.status == 'draft' ? '#d38128' : x.status == 'publish' ? 'blue' : x.status == 'shortlist' ? 'green' : x.status == 'reject' ? 'darkred' : ''}">
                                {{x.status}}</div>
                        </div>
                        <div class="dos">
                            <span style="font-size: 10px">Updated {{x.updated_at | timeAgo}}</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Rejected ({{rejectedStatements?.length}})">
        <div style="overflow-x: hidden;">
            <div class="tab-holder" *ngIf="rejectedStatements">
                <div class="flex-container-1">
                    <div *ngIf="rejectedStatements?.length <= 0">
                        <p class="notfound">No record found</p>
                    </div>
                    <div class="flex-item p-1" *ngFor="let x of rejectedStatements;">
                        <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                            <img src="assets/images/edit.png" width="20px" (click)="editIdea(x); $event.stopPropagation()" *ngIf="x.status == 'draft'" style="position: absolute; top: 10px;  right: 10px;">
                            <div class="d-flex justify-content-between">
                                <h6 class="mb-0 trn-nm" style="width: 80%; font-size: 12px">{{x.title}}</h6>
                                <span class="tag-icon"  *ngIf="x?.tag_ids?.length > 0" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" > <i class="far fa-tag" style="font-size: 10px;"></i></span>
                            </div>
                            <mat-menu #menu="matMenu" class="my-class">
                                <app-tag [tagsIds]="x?.tag_ids"></app-tag>
                            </mat-menu>
                            <p class="desc">{{x.description}}</p>
                            <div>
                                <p class="m-0 mt-1" style="font-size: 8px;">Submitted by</p>
                                <div class="m-0 d-flex" style="font-size: 12px; font-weight: 800;">
                                    <div>
                                        <img *ngIf="x?.vendor?.url" src="{{x?.vendor?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                                        <span class="name-1" *ngIf="!x?.vendor?.url"   [ngStyle]="{'backgroundColor': x?.vendor?.url}">{{getName(x.vendor.name)}}</span>
                                    </div>
                                    <h6 class="mb-0 trn-nm ml-2 mt-2">{{x?.vendor?.name}}</h6>
                                </div>
                            </div>
                            <!-- <div style="padding: 10px; text-align: center; margin: 0 -15px;">
                                <app-tag [dataObj]="getTagObject(x.id)" [tagsIds]="x?.tag_ids" [slices]="1"></app-tag>
                            </div> -->
                            <div class="st-col" [ngStyle]="{'background': x.status == 'draft' ? '#d38128' : x.status == 'publish' ? 'blue' : x.status == 'shortlist' ? 'green' : x.status == 'reject' ? 'darkred' : ''}">
                                {{x.status}}</div>
                        </div>
                        <div class="dos">
                            <span style="font-size: 10px">Updated {{x.updated_at | timeAgo}}</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <!-- <mat-tab label="Completed ({{completedStatements?.length}})">
        <div style="overflow-x: hidden;">
            <div class="row mt-2">
                <div class="col-sm-8">
                    <div style="overflow-x: scroll">
                        <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
                    </div>
                </div>
                <div class="col-sm-4 trn-tc-2">
                    <input type="text" placeholder="Search ..." class="form-control">
                </div>
            </div>
            <div class="tab-holder" *ngIf="completedStatements">
                <div class="flex-container-1">
                    <div *ngIf="completedStatements?.length <= 0">
                        <p class="notfound">No record found</p>
                    </div>
                    <div class="flex-item p-1" *ngFor="let x of completedStatements;">
                        <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                            <img src="assets/images/edit.png" width="20px" (click)="editIdea(x); $event.stopPropagation()" *ngIf="x.status == 'draft'" style="position: absolute; top: 10px;  right: 10px;">
                            <h6 class="mb-0 trn-nm" style="width: 80%">{{x.title}}</h6>
                            <p class="desc">{{x.description}}</p>
                            <div>
                                <p class="m-0 mt-1" style="font-size: 8px;">Submitted by</p>
                                <p class="m-0" style="font-size: 12px; font-weight: 800;"><img [src]="x?.service_provider?.url" style="width: 20px; height: 20px; margin-right: 10px"> {{x.service_provider.name}}</p>
                            </div>
                            <div style="padding: 10px; text-align: center; margin: 0 -15px;">
                                <app-tag [dataObj]="getTagObject(x.id)" [tagsIds]="x?.tag_ids" [slices]="1"></app-tag>
                            </div>
                            <div class="st-col" [ngStyle]="{'background': x.status == 'draft' ? '#d38128' : x.status == 'published' ? 'blue' : x.status == 'shortlist' ? 'green' : x.status == 'reject' ? 'darkred' : ''}">
                                {{x.status}}</div>
                        </div>
                        <div class="dos">
                            <span style="font-size: 10px">{{x.updated_at | timeAgo}}</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab> -->
</mat-tab-group>